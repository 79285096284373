import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'

export const state = {
  performanceStatistics: {},

  /** Employees **/
  employees: [],
  employeePerformance: null,

  /** Discipline **/
  disciplinePerformance: [],

  /** Team **/
  teamPerformance: [],

  navigatedFrom: null
}

const namespaced = false

export const performance = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
