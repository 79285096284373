const graphTexts = {
  averageProActiveLogging: 'Average proactive logging',
  averageRemindedLogging: 'Average reminded logging',
  averageWeeklyRating: 'Average weekly rating',
}

const graphDefaultColors = {
  averageProActiveLogging: true,
  averageRemindedLogging: true,
  averageWeeklyRating: false,
}

export default class PerformanceStatistic {
  constructor({averageProActiveLogging, averageRemindedLogging, averageWeeklyRating}) {
    this.averageWeeklyRating = averageWeeklyRating;
    this.averageProActiveLogging = averageProActiveLogging;
    this.averageRemindedLogging = averageRemindedLogging ;
  }

  disableProActiveLogging() {
    this.averageProActiveLogging = null
  } 

  disableRemindedLogging() {
    this.averageRemindedLogging = null
  } 

  graphText(property) {
    return graphTexts[property];
  }

  hasDefaultColor(property) {
    return graphDefaultColors[property];
  }

  static create(data) {
    return new PerformanceStatistic(data)
  }
}