import {capitalize} from "eslint-plugin-vue/lib/utils/casing";

export default class Employee {
  constructor({id, status, displayName, discipline, slackId, jiraId, teamName, teamId, acceptableWorkPercentage, hours}) {
    this.id = id;
    this.status = status;
    this.displayName = displayName;
    this.discipline = discipline;
    this.slackId = slackId ? slackId : ''; // If slackId is null display an empty string
    this.jiraId = jiraId;
    this.teamName = teamName ? teamName : ''; // If teamName is null display an empty string
    this.teamId = teamId;
    this.acceptableWorkPercentage = acceptableWorkPercentage ? acceptableWorkPercentage : ''; // If acceptableWorkPercentage is null display an empty string
    this.hours = hours ? hours : ''; // If hours is null display an empty string
  }

  /**
   * Display name
   */
  formattedName(text) {
    if (!text) {
      return this.displayName;
    }
    const regex = new RegExp(text, "ig");
    return this.displayName.replace(regex, (str) => `<b class="searched">${str}</b>`); // Make the text's characters bold.
  }

  /**
   * Status
   */
  get statusTextLowercase() {
    if (this.status === EMPLOYEE_STATUS.ACTIVE) {
      return EMPLOYEE_STATUS_TEXTS.ACTIVE;
    } else if (this.status === EMPLOYEE_STATUS.INACTIVE) {
      return EMPLOYEE_STATUS_TEXTS.INACTIVE;
    } else if (this.status === EMPLOYEE_STATUS.INCOMPLETE) {
      return EMPLOYEE_STATUS_TEXTS.INCOMPLETE;
    }
    return '';
  }

  get statusText() {
    if (this.status === EMPLOYEE_STATUS.ACTIVE) {
      return capitalize(EMPLOYEE_STATUS_TEXTS.ACTIVE);
    } else if (this.status === EMPLOYEE_STATUS.INACTIVE) {
      return capitalize(EMPLOYEE_STATUS_TEXTS.INACTIVE);
    } else if (this.status === EMPLOYEE_STATUS.INCOMPLETE) {
      return capitalize(EMPLOYEE_STATUS_TEXTS.INCOMPLETE);
    }
    return '';
  }

  static create(data) {
    return new Employee(data)
  }
}

export const EMPLOYEE_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
  INCOMPLETE: 2
}

export const EMPLOYEE_STATUS_TEXTS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  INCOMPLETE: 'incomplete'
}
