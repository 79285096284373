export const graphTexts = {
  averageGeneralBillabilityRatio: 'Average General <br> billability ratio',
  averageCorrectedBillabilityRatio: 'Average Corrected <br> billability ratio',
  averageGeneralBillabilityRatioChange: 'Average change <br> General billability ratio',
  averageCorrectedBillabilityRatioChange: 'Average change <br> Corrected billability ratio'
}

export default class BillabilityStatistic {
  constructor({averageGeneralBillabilityRatio, averageCorrectedBillabilityRatio}) {
    this.averageGeneralBillabilityRatio = averageGeneralBillabilityRatio;
    this.averageCorrectedBillabilityRatio = averageCorrectedBillabilityRatio;
  }

  graphText(property) {
    return graphTexts[property];
  }

  static create(data) {
    return new BillabilityStatistic(data)
  }
}