import Billability from "@/models/billability/Billability";
import BillabilityCompared from "@/models/billability/BillabilityCompared";
import BillabilityStatistic from "@/models/billability/BillabilityStatistic";
import BillabilityComparedStatistic from "@/models/billability/BillabilityComparedStatistic";
import {TYPES_SORTING} from "@/models/SortingHandler";
import vuexStore from "@/store";
import Discipline from '@/models/disciplines/Discipline';

/** Employees **/
export const SET_BILLABILITY_EMPLOYEES = 'setBillabilityEmployees'
export const SET_EMPLOYEE_BILLABILITY = 'setEmployeeBillability'
export const SET_EMPLOYEE_CORRECTED_BILLABILITY = 'setEmployeeCorrectedBillability'
export const CLEAR_SECOND_PERIOD_BILLABILITY_DATA = "clearSecondPeriodBillabilityData";

/** Disciplines **/
export const SET_BILLABILITY_DISCIPLINES = 'setBillabilityDisciplines'
export const SET_DISCIPLINE_BILLABILITY = 'setDisciplineBillability'
export const SET_DISCIPLINE_CORRECTED_BILLABILITY = 'setDisciplineCorrectedBillability'
export const CLEAR_COMPARED_DISCIPLINE_BILLABILITY_DATA = 'clearComparedDisciplineBillabilityData'
export const SET_DISCIPLINES = 'setDisciplines';

/** Teams **/
export const SET_BILLABILITY_TEAMS = 'setBillabilityTeams'
export const SET_TEAM_BILLABILITY = 'setTeamBillability'
export const SET_TEAM_CORRECTED_BILLABILITY = 'setTeamCorrectedBillability'

/** Statistics **/
export const SET_BILLABILITY_STATISTICS = 'setBillabilityStatistics'
export const SET_BILLABILITY_STATISTICS_SECOND_PERIOD = 'setBillabilityStatisticsSecondPeriod'
export const CLEAR_SECOND_PERIOD_BILLABILITY_STATISTICS = "clearSecondPeriodBillabilityStatistics";
export const CLEAR_STATISTICS = 'clearStatistics';

/** Sorting **/
export const SORT_BILLABILITY_DATA = 'sortBillabilityData'
export const SORT_BILLABILITY_DETAILS_DATA=  'sortBillabilityDetailsData'
export const SORT_EMPLOYEE_BILLABILITY = 'sortEmployeeBillability'
export const SORT_EMPLOYEE_BILLABILITY_DETAILS = 'sortEmployeeBillabilityDetails'

export const SORT_DISCIPLINE_BILLABILITY = 'sortDisciplineBillability'
export const SORT_DISCIPLINE_BILLABILITY_DETAILS = 'sortDisciplineBillabilityDetails'

export const SORT_TEAM_BILLABILITY = 'sortTeamBillability'
export const SORT_TEAM_BILLABILITY_DETAILS = 'sortTeamBillabilityDetails'

export const mutations = {
  /** Employees **/
  setBillabilityEmployees(state, data) {
    // Store second period data in different variable for comparing
    if(data.isEndDateComparison) {
      // In order to show all of the data at all times, the selected data will replace the entire list of billabilityEmployees
      const allEmplopyeeData = state.billabilityEmployees.map(employee => {
        const matchingSelectedEmployee = data.employees.find(selectedEmployee => selectedEmployee.id === employee.id);
      
        // If there is a matching object in data.employee, replace the object in state.billabilityEmployees with it
        if (matchingSelectedEmployee) {
          return { ...employee, ...matchingSelectedEmployee };
        }
      
        // If no matching object in data.employee, keep the object from state.billabilityEmployees as it is
        return employee;
      });
      state.billabilityEmployeesSecondPeriod = allEmplopyeeData.map(item => BillabilityCompared.create(item));
      this.commit(SET_BILLABILITY_STATISTICS_SECOND_PERIOD, data)
    }
    else {
      if (!data.jiraIds) {
        state.billabilityEmployees = data.employees.map(item => Billability.create(item));
      }
      this.commit(SET_BILLABILITY_STATISTICS, data)
    }

    if (vuexStore.state.sortingOptions) {
      this.commit(SORT_EMPLOYEE_BILLABILITY);
    }
  },
  setEmployeeBillability(state, data) {
    this.commit(SET_BILLABILITY_STATISTICS, data)
    state.employeeBillability = data.employee;
  },

  setEmployeeCorrectedBillability(state, data){
    state.employeeCorrectedBillability = data
  },

  clearSecondPeriodBillabilityData(state) {
    state.billabilityEmployeesSecondPeriod = [];
  },

  /** Disciplines **/
  setBillabilityDisciplines(state, data) {
    // Store second period data in different variable for comparing
    if(data.isEndDateComparison) {
      // In order to show all of the data at all times, the selected data will replace the entire list of billabilityDisciplines
      const allDisciplineData = state.billabilityDisciplines.map(discipline => {
        const matchingSelectedDiscipline = data.disciplines.find(selectedDiscipline => selectedDiscipline.id == discipline.id);
        
        // If there is a matching object in data.disciplines, replace the object in state.billabilityDisciplines with it
        if (matchingSelectedDiscipline) {
          return { ...discipline, ...matchingSelectedDiscipline };
        }
      
        // If no matching object in data.disciplines, keep the object from state.billabilityDisciplines as it is
        return discipline;
      });
      state.comparedBillabilityDisciplines = allDisciplineData.map(item => BillabilityCompared.create(item));
      this.commit(SET_BILLABILITY_STATISTICS_SECOND_PERIOD, data);
    } else {
      if (!data.disciplineIds) {
        state.billabilityDisciplines = data.disciplines.map(item => Billability.create(item));
      }
      this.commit(SET_BILLABILITY_STATISTICS, data);
    }
  },

  setDisciplineBillability(state, data) {
    this.commit(SET_BILLABILITY_STATISTICS, data)
    state.disciplineBillability = data.discipline;
  },

  setDisciplineCorrectedBillability(state, data){
    state.disciplineCorrectedBillability = data
  },

  clearComparedDisciplineBillabilityData(state) {
    state.comparedBillabilityDisciplines = [];
  },

  setDisciplines(state, data) {
    state.disciplineList = data.map(item => Discipline.create(item));
  },

  /** Teams **/
  setBillabilityTeams(state, data) {
    if(data.isEndDateComparison) {
      // In order to show all of the data at all times, the selected data will replace the entire list of billabilityDisciplines
      const allTeamData = state.billabilityTeams.map(team => {
        const matchingSelectedTeam = data.teams.find(selectedTeam => selectedTeam.id == team.id);
        
        // If there is a matching object in data.disciplines, replace the object in state.billabilityDisciplines with it
        if (matchingSelectedTeam) {
          return { ...team, ...matchingSelectedTeam };
        }
      
        // If no matching object in data.disciplines, keep the object from state.billabilityDisciplines as it is
        return team;
      });
      state.teamBillabilityCompared = allTeamData.map(item => BillabilityCompared.create(item));
      this.commit(SET_BILLABILITY_STATISTICS_SECOND_PERIOD, data)
    }
    else {
      if (!data.teamIds) {
        state.billabilityTeams = data.teams.map(item => Billability.create(item));
      }
      this.commit(SET_BILLABILITY_STATISTICS, data)
    }
  },

  setTeamBillability(state, data) {
    this.commit(SET_BILLABILITY_STATISTICS, data)
    state.teamBillability = data.team;
  },

  setTeamCorrectedBillability(state, data) {
    state.teamCorrectedBillability = data
  },

  /** Statistics **/
  setBillabilityStatistics(state, data) {
    state.billabilityStatistics = BillabilityStatistic.create(data);
  },

  setBillabilityStatisticsSecondPeriod(state, data) {
    state.billabilityStatisticsSecondPeriod = BillabilityComparedStatistic.create(data);
  },

  clearSecondPeriodBillabilityStatistics(state) {
    state.billabilityStatisticsSecondPeriod = {}
  },

  clearStatistics(state) {
    state.billabilityStatistics = {}
    state.billabilityStatisticsSecondPeriod = {}
  },

  /** Sorting **/
  sortBillabilityData(state, property) {
    const {sortingType, sortingKey} = vuexStore.state.sortingOptions;
    state[property] = state[property].sort((item1, item2) => {
      if (sortingType === TYPES_SORTING.DESCENDING) {
        return item1[sortingKey] > item2[sortingKey] ? 1 : (item1[sortingKey] < item2[sortingKey] ? -1 : 0)
      } else {
        return item1[sortingKey] < item2[sortingKey] ? 1 : (item1[sortingKey] > item2[sortingKey] ? -1 : 0)
      }
    });
  },
  sortBillabilityDetailsData(state, property) {
    const {sortingType, sortingKey} = vuexStore.state.sortingOptions;
    state[property].data = state[property].data.sort((item1, item2) => {
      if (sortingType === TYPES_SORTING.DESCENDING) {
        return item1[sortingKey] > item2[sortingKey] ? 1 : (item1[sortingKey] < item2[sortingKey] ? -1 : 0)
      } else {
        return item1[sortingKey] < item2[sortingKey] ? 1 : (item1[sortingKey] > item2[sortingKey] ? -1 : 0)
      }
    });
  },

  /** Sorting - Employees **/
  sortEmployeeBillability(state) {
    this.commit(
      SORT_BILLABILITY_DATA,
      state.billabilityEmployeesSecondPeriod.length > 0
        ? "billabilityEmployeesSecondPeriod"
        : "billabilityEmployees"
    );
  },
  sortEmployeeBillabilityDetails(state) {
    this.commit(SORT_BILLABILITY_DETAILS_DATA, 'employeeBillability');
  },

  /** Sorting - Discipline **/
  sortDisciplineBillability(state) {
    this.commit(
      SORT_BILLABILITY_DATA,
      state.comparedBillabilityDisciplines.length > 0 
      ? 'comparedBillabilityDisciplines' 
      : 'billabilityDisciplines');
  },
  sortDisciplineBillabilityDetails(state) {
    this.commit(SORT_BILLABILITY_DETAILS_DATA, 'disciplineBillability');
  },

  /** Sorting - Team **/
  sortTeamBillability(state) {
    this.commit(SORT_BILLABILITY_DATA, 'billabilityTeams');
  },
  sortTeamBillabilityDetails(state) {
    this.commit(SORT_BILLABILITY_DETAILS_DATA, 'teamBillability');
  },
}
