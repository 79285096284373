import Users from "@/models/users/Users";

export const SET_USERS = 'setUsers';
export const UPDATE_USER_ROLE = 'updateUserRole';

export const mutations = {
  setUsers(state, data) {
    state.users = data.map(item => Users.create(item));
  },
  
  updateUserRole(state, data) {
    data.usersIds.forEach((id) => {
      const user = state.users.find(user => user.id === id);
        user.role = data.role;
    });    
  }
}