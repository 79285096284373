import Absence from "@/models/Absence/Absence";
import vuexStore from "@/store";
import {TYPES_SORTING} from "@/models/SortingHandler";

export const SET_ABSENCES = 'setAbsences';
export const SORT_ABSENCES = 'sortAbsences';

export const mutations = {
  setAbsences(state, data) {
    state.absences = data.map(absence => Absence.create(absence));
    if (vuexStore.state.sortingOptions) {
      this.commit(SORT_ABSENCES);
    }
  },
  sortAbsences(state) {
    const {sortingType, sortingKey} = vuexStore.state.sortingOptions;
    state.absences = state.absences.sort((item1, item2) => {
      if (sortingType === TYPES_SORTING.DESCENDING) {
        return item1.absences[0][sortingKey] > item2.absences[0][sortingKey] ? 1 : (item1.absences[0][sortingKey] < item2.absences[0][sortingKey] ? -1 : 0)
      } else {
        return item1.absences[0][sortingKey] < item2.absences[0][sortingKey] ? 1 : (item1.absences[0][sortingKey] > item2.absences[0][sortingKey] ? -1 : 0)
      }
    });
  }
}
