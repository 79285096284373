export default class Discipline {
  constructor({id, name}) {
    this.id = id;
    this.name = name;
  }

  static create(data) {
    return new Discipline(data);
  }
}
