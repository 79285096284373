<template>
  <div id="app">
    <router-view/>

    <!-- Loading indicator -->
    <LoadingSpinner v-if="!hideLoadingSpinner" :is-loading="isLoading"/>
  </div>
</template>

<style lang="scss">
</style>
<script>
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import {useStore} from "vuex";
import {computed} from "vue";
import {useRoute} from "vue-router";

export default {
  components: {
    LoadingSpinner
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    /** Loading indicator **/
    const isLoading = computed(() => store.getters.isPageLoading);
    const hideLoadingSpinner = computed(() => route.meta.hideLoadingSpinner);

    return {
      /** Loading indicator **/
      isLoading,
      hideLoadingSpinner
    }
  }
}
</script>

<style lang="scss">
</style>
