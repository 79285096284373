export default class Users {
  constructor({userId, name, email, role, createdAt}) {
    this.name = name;
    this.email = email;
    this.role = role;
    this.createdAt = createdAt;
    this.id = userId;
  }

   /**
   * Display name
   */
   formattedName(text) {
    if (!text) {
      return this.name;
    }
    const regex = new RegExp(text, "ig");
    return this.name.replace(regex, (str) => `<b class="searched">${str}</b>`); // Make the text's characters bold.
  }

  static create(data) {
    return new Users(data);
  }
}

export const bulkOptions = [
  {
    name: 'Super Admin',
    queryName: 'admin',
    id: 1
  },
  {
    name: 'App user',
    queryName: 'app',
    id: 2
  }
];

export const filterOptions = [
  {
    name:'All',
    queryName: '',
    id: 1
  }, 
  {
    name:'Super Admin',
    queryName: 'admin',
    id: 2
  }, 
  {
    name: 'App user',
    queryName: 'app',
    id: 3
  }
]