import apiErrorHandler from "@/utils/handlers/errorHandler/ApiErrorHandler";

/** Modal **/
export const SET_MODAL_OPTIONS = 'setModalOptions';
export const RESET_MODAL_OPTIONS = 'resetModalOptions';
export const SET_ACTIVE_MODAL = 'setActiveModal';

/** Toast **/
export const SET_TOAST_OPTIONS = 'setToastOptions';
export const RESET_TOAST_OPTIONS = 'resetToastOptions';

/** API ERROR **/
export const SET_API_ERROR = 'setApiError';

/** Loading spinner **/
export const SET_LOADING = 'setLoading';

/** Pagination **/
export const SET_PAGINATION_DATA = 'setPaginationData';
export const RESET_PAGINATION_DATA = 'resetPaginationData';

/** Sorting **/
export const SET_SORTING_OPTIONS = 'setSortingOptions';

/** Breadcrumb **/
export const SET_BREADCRUMB_NAME = 'setBreadcrumbName';
export const RESET_BREADCRUMB_NAME = 'resetBreadcrumbName';

export const mutations = {
  /** Modal **/
  setModalOptions(state, {modalContent, activeModal}) {
    state.modalContent = modalContent;
    state.activeModal = activeModal;
    state.isModalShown = true;
  },
  resetModalOptions(state) {
    state.modalContent = null;
    state.isModalShown = false;
    state.activeModal = null;
  },
  setActiveModal(state, activeModal) {
    state.activeModal = activeModal;
  },

  /** Toast **/
  setToastOptions(state, data) {
    state.toastOptions = data;
  },
  resetToastOptions(state) {
    state.toastOptions = null;
  },

  /** API Error **/
  setApiError(state, error) {
    state.apiError = apiErrorHandler.getErrorText(error.response.data)
  },

  /** Loading spinner **/
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },

  /** Pagination **/
  setPaginationData(state, data) {
    // state.paginationData = PaginationData.parseDataFromObject(data);
    state.paginationData = data;
  },
  resetPaginationData(state) {
    state.paginationData = null;
  },

  /** Sorting **/
  setSortingOptions(state, sortingOptions) {
    state.sortingOptions = sortingOptions;
  },

  /** Breadcrumbs **/
  setBreadcrumbName(state, name) {
    state.breadcrumbName = name;
  },

  resetBreadcrumbName(state) {
    state.breadcrumbName = '';
  }
}
