import {createRouter, createWebHistory} from 'vue-router';
import modules from '@/router/modules';
import {ROUTE_NAMES_PERFORMANCE} from "@/router/modules/performance";
import LocalDataHandler from '@/utils/handlers/LocalDataHandler'
import {ROUTE_NAMES_ROOT} from "@/router/modules/";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: modules,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return {top: 0};
  },
});

router.beforeEach(async (to, from, next) => {
  // User enters an invalid URL.
  if (to.matched.length === 0) {
    return next({name: ROUTE_NAMES_PERFORMANCE.PERFORMANCE});
  }

  // User enters a valid URL.
  for (const route of to.matched) {
    if (route.meta && route.meta.guard) {
      /** Let each guard handle route access **/
      const result = await route.meta.guard.handleRouteEnter();
      if (result !== true) {
        return next({name: result});
      } else {
        const userRole = LocalDataHandler.getUserType();
        if (route.meta.roles && !route.meta.roles.some(role => userRole.includes(role))) {
          return next({name: ROUTE_NAMES_ROOT.LOGOUT}); // Redirect if the user lacks required role
        }
      }
    }
  }

  return next();
});

export default router;
