export const getters = {
  getPerformanceStatistics(state) {
    return state.performanceStatistics;
  },

  /** Employees **/
  getPerformanceEmployees(state) {
    return state.employees;
  },
  getEmployeePerformance(state) {
    return state.employeePerformance;
  },

  /** Discipline **/
  getDisciplinePerformance(state) {
    return state.disciplinePerformance;
  },

  /** Team **/
  getTeamPerformance(state) {
    return state.teamPerformance;
  },

  getNavigatedFrom(state) {
    return state.navigatedFrom;
  }
}
