export default class Team {
  constructor({id, name, slackId}) {
    this.id = id;
    this.name = name;
    this.slackId = slackId;
  }

  static create(data) {
    return new Team(data);
  }
}
