import {auth} from '@/router/modules/auth'
import {manager} from '@/router/modules/manager'
import NotFound from '@/views/NotFound'
import Logout from "@/views/Logout";
import {employees} from "@/router/modules/employees";
import {performance} from "@/router/modules/performance";
import {teams} from "@/router/modules/teams";
import {absence} from "@/router/modules/absence";
import {billability} from "@/router/modules/billability";
import {users} from "@/router/modules/users";

export const ROUTE_NAMES_ROOT = Object.freeze({
  LOGOUT: 'Logout',
  NOT_FOUND: 'Not Found'
});

/** routes without layout **/
export const root = [
  {
    path: '/logout',
    name: ROUTE_NAMES_ROOT.LOGOUT,
    component: Logout
  },
  {
    // not found
    path: '/not-found',
    name: ROUTE_NAMES_ROOT.NOT_FOUND,
    component: NotFound
  },
]

export default root.concat([auth, manager, employees, performance, teams, absence, billability, users]);
