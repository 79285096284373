import PerformanceItem from "@/models/performance/PerformanceItem";

export default class EmployeePerformanceDetails extends PerformanceItem {
  constructor({
                expectedHours,
                link,
                loggedHours,
                loggedPercentage,
                proActiveLoggedPercentage,
                remindedLoggedPercentage,
                week,
                id = null
              }) {
    super({expectedHours, id, loggedHours, loggedPercentage});
    this.link = link;
    this.proActiveLoggedPercentage = proActiveLoggedPercentage;
    this.remindedLoggedPercentage = remindedLoggedPercentage;
    this.week = week;
  }

  static create(data) {
    return new EmployeePerformanceDetails(data);
  }
}