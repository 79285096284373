import AuthGuard from '@/router/guards/auth';

const ManagerLayout = () => import('../../views/manager/Layout');
const Overview = () => import('../../views/manager/performance/Overview');
const EmployeeDetails = () => import('../../views/manager/performance/EmployeeDetails');

export const ROUTE_NAMES_PERFORMANCE = Object.freeze({
  PERFORMANCE: 'PerformanceOverview',
  EMPLOYEE_DETAILS: 'EmployeeDetails',
});

export const performance = {
  path: '/performance',
  meta: {
    guard: AuthGuard,
    roles: ['admin', 'app'],
  },
  component: ManagerLayout,
  children: [
    {
      path: '',
      name: ROUTE_NAMES_PERFORMANCE.PERFORMANCE,
      component: Overview
    },
    {
      path: 'details/:employeeId',
      name: ROUTE_NAMES_PERFORMANCE.EMPLOYEE_DETAILS,
      component: EmployeeDetails,
      meta: {
        // Used in the Breadcrumbs.vue
        backButtonRoute: ROUTE_NAMES_PERFORMANCE.PERFORMANCE,

        breadcrumb: [
          {
            name: ROUTE_NAMES_PERFORMANCE.PERFORMANCE,
            text: 'performance'
          },
          {
            name: ROUTE_NAMES_PERFORMANCE.EMPLOYEE_DETAILS,
            textWithName: (name = "") => `${name}`
          },
        ],
      }
    },
  ]
}
