export default {
  en: {
    default: "There is an error, please try again later.",
    conflict_team_name_exists: "This team already exists.",
    invalid_request: "Cannot change permissions of own user",
    user_already_exist: "The user with the given email already exists",
  },
  nl: {
    default: "Er is een fout, probeer het later opnieuw.",
  }
}

