import apiHandler from '@/utils/handlers/ApiHandler';
import {
  SET_BILLABILITY_EMPLOYEES, 
  SET_EMPLOYEE_BILLABILITY, 
  SET_EMPLOYEE_CORRECTED_BILLABILITY,
  SET_BILLABILITY_DISCIPLINES,
  SET_DISCIPLINE_BILLABILITY,
  SET_DISCIPLINE_CORRECTED_BILLABILITY,
  SET_BILLABILITY_TEAMS,
  SET_TEAM_BILLABILITY,
  SET_TEAM_CORRECTED_BILLABILITY,
  SET_DISCIPLINES
} from "@/store/billability/mutations";

/** Employees **/
export const GET_BILLABILITY_EMPLOYEES = 'getBillabilityEmployees';
export const GET_EMPLOYEE_BILLABILITY = 'getEmployeeBillability';
export const GET_EMPLOYEE_CORRECTED_BILLABILITY = 'getEmployeeCorrectedBillability';

/** Disciplines **/
export const GET_BILLABILITY_DISCIPLINES = 'getBillabilityDiscipline';
export const GET_DISCIPLINE_BILLABILITY = 'getDisciplineBillability';
export const GET_DISCIPLINE_CORRECTED_BILLABILITY = 'getDisciplineCorrectedBillability';
export const GET_DISCIPLINES = 'getDisciplines';

/** Teams **/
export const GET_BILLABILITY_TEAMS = 'getBillabilityTeams';
export const GET_TEAM_BILLABILITY = 'getTeamBillability'
export const GET_TEAM_CORRECTED_BILLABILITY = 'getTeamCorrectedBillability'

/** Reset Slackbot */
export const RESET_SLACKBOT = 'resetSlackbot'

export const actions = {
  /** Employees **/
  getBillabilityEmployees({commit}, payload) {
    const data = {
      startDate: payload.filters.firstPeriodStartDate ? payload.filters.firstPeriodStartDate : payload.filters.start,
      endDate: payload.filters.firstPeriodEndDate ? payload.filters.firstPeriodEndDate : payload.filters.end,
      startDateComparison: payload.filters.firstPeriodStartDate ? payload.filters.start : null,
      endDateComparison: payload.filters.firstPeriodEndDate ? payload.filters.end : null,
      searchQuery: payload.filters.searchQuery ? payload.filters.searchQuery : null,
      disciplineIds: payload.filters.disciplineIds,
      contractIds: payload.filters.contractIds,
      jiraIds: payload.jiraIds.length > 0 ? payload.jiraIds : null
    }
    return apiHandler.get(`/billability/employees`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        // Add indication if start or end date for comparing
        response.data.isEndDateComparison = payload.filters.isEndDateComparison;
        response.data.jiraIds = data.jiraIds;
        commit(SET_BILLABILITY_EMPLOYEES, response.data);
        return true;
      }
      return false;
    })
  },
  getEmployeeBillability({commit}, payload) {
    const data = {
      year: payload.year,
      interval: payload.interval,
    }
    return apiHandler.get(`/billability/employees/${payload.employeeId}`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_EMPLOYEE_BILLABILITY, response.data);
        return true;
      }
      return false;
    })
  },
  getEmployeeCorrectedBillability({commit}, {employeeId, startDate, endDate}) {
    const data = {
      startDate,
      endDate
    }
    return apiHandler.get(`/billability/employees/${employeeId}/corrected`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_EMPLOYEE_CORRECTED_BILLABILITY, response.data);
        return true;
      }
      return false;
    })
  },

  /** Discipline **/
  getBillabilityDiscipline({commit}, payload) {
    const data = {
      startDate: payload.filters.start,
      endDate: payload.filters.end,
      startDateComparison: payload.filters.startDateComparison ? payload.filters.startDateComparison : null,
      endDateComparison: payload.filters.endDateComparison ? payload.filters.endDateComparison : null,
      searchQuery: payload.filters.searchQuery ? payload.filters.searchQuery : null,
      disciplineIds: payload.disciplineIds.length > 0 ? payload.disciplineIds : null
    }
    return apiHandler.get(`/billability/disciplines`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        // Add indication if start or end date for comparing
        response.data.isEndDateComparison = payload.filters.isEndDateComparison;
        response.data.disciplineIds = data.disciplineIds;
        commit(SET_BILLABILITY_DISCIPLINES, response.data);
        return true;
      }
      return false;
    })
  },
  getDisciplineBillability({commit}, payload) {
    const data = {
      year: payload.year,
      interval: payload.interval
    }
    return apiHandler.get(`/billability/disciplines/${payload.disciplineId}`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_DISCIPLINE_BILLABILITY, response.data);
        return true;
      }
      return false;
    })
  },
  getDisciplineCorrectedBillability({commit}, {disciplineId, startDate, endDate}) {
    const data = {
      startDate,
      endDate
    }
    return apiHandler.get(`/billability/disciplines/${disciplineId}/corrected`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_DISCIPLINE_CORRECTED_BILLABILITY, response.data);
        return true;
      }
      return false;
    })
  },

  getDisciplines({commit}) {
    return apiHandler.get('/discipline').then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_DISCIPLINES, response.data.disciplines);
        return true;
      }
      return false;
    })
  },
  
  /** Teams **/
  getBillabilityTeams({commit}, payload) {
    const data = {
      startDate: payload.filters.start,
      endDate: payload.filters.end,
      startDateComparison: payload.filters.startDateComparison ? payload.filters.startDateComparison : null,
      endDateComparison: payload.filters.endDateComparison ? payload.filters.endDateComparison : null,
      searchQuery: payload.filters.searchQuery ? payload.filters.searchQuery : null,
      teamIds: payload.teamIds.length > 0 ? payload.teamIds : null
    }
    return apiHandler.get(`/billability/teams`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        response.data.isEndDateComparison = payload.filters.isEndDateComparison;
        response.data.teamIds = data.teamIds;
        commit(SET_BILLABILITY_TEAMS, response.data);
        return true;
      }
      return false;
    })
  },
  getTeamBillability({commit}, payload) {
    const data = {
      year: payload.year,
      interval: payload.interval
    }
    return apiHandler.get(`/billability/teams/${payload.teamId}`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_TEAM_BILLABILITY, response.data);
        return true;
      }
      return false;
    })
  },
  getTeamCorrectedBillability({commit}, {teamId, startDate, endDate}) {
    const data = {
      startDate,
      endDate
    }
    return apiHandler.get(`/billability/teams/${teamId}/corrected`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_TEAM_CORRECTED_BILLABILITY, response.data);
        return true;
      }
      return false;
    })
  },

  /** Reset Slackbot */
  resetSlackbot({commit}) {
    return apiHandler.post(`/billabilityemployees/clear-week-history`).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    })
  }
}
