import AuthGuard from '@/router/guards/auth';

const ManagerLayout = () => import('../../views/manager/Layout.vue');
const Dashboard = () => import('../../views/manager/Dashboard.vue');

export const ROUTE_NAMES_MANAGER = Object.freeze({
  DASHBOARD: 'ManagerDashboard',
});

export const manager = {
  path: '/manager',
  meta: {
    guard: AuthGuard
  },
  component: ManagerLayout,
  children: [
    {
      path: 'dashboard',
      name: ROUTE_NAMES_MANAGER.DASHBOARD,
      component: Dashboard
    },
  ]
}
