import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'

export const state = {
  employees: [],
}

const namespaced = false

export const employees = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
