import AuthGuard from '@/router/guards/auth';

const ManagerLayout = () => import('../../views/manager/Layout');
const Users = () => import('../../views/manager/users/Users');

export const ROUTE_NAMES_USERS = Object.freeze({
  OVERVIEW: 'UsersOverview'
});

export const users = {
  path: '/users',
  meta: {
    guard: AuthGuard,
    roles: ['admin'],
  },
  component: ManagerLayout,
  children: [
    {
      path: '',
      name: ROUTE_NAMES_USERS.OVERVIEW,
      component: Users
    }
  ]
}
