export default class PerformanceItem {
  constructor({expectedHours, id, loggedHours, loggedPercentage}) {
    this.expectedHours = expectedHours;
    this.id = id;
    this.loggedHours = loggedHours;
    this.loggedPercentage = loggedPercentage;
  }

  get loggedHoursFraction() {
    if (!this.expectedHours || 0 > this.expectedHours) {
      return '0/0';
    }
    return `${Math.round(this.loggedHours)}/${Math.round(this.expectedHours)}`;
  }

  static create(data) {
    return new PerformanceItem(data)
  }
}