export const getters = {
  /** Employees **/
  getBillabilityEmployees(state) {
    return state.billabilityEmployees;
  },

  getBillabilityEmployeesSecondPeriod(state) {
    return state.billabilityEmployeesSecondPeriod
  },
  
  getEmployeeBillability(state) {
    return state.employeeBillability
  },
  
  getEmployeeCorrectedBillability(state) {
    return state.employeeCorrectedBillability
  },
   
  /** Disciplines **/
  getBillabilityDisciplines(state) {
    return state.billabilityDisciplines
  },

  getComparedBillabilityDisciplines(state) {
    return state.comparedBillabilityDisciplines
  },

  getDisciplineBillability(state) {
    return state.disciplineBillability
  },

  getDisciplineCorrectedBillability(state) {
    return state.disciplineCorrectedBillability
  },

  getDisciplineList(state) {
    return state.disciplineList;
  },

  /** Team **/
  getBillabilityTeams(state) {
    return state.billabilityTeams;
  },

  getTeamBillability(state) {
    return state.teamBillability
  },

  getTeamCorrectedBillability(state) {
    return state.teamCorrectedBillability
  },

  getTeamBillabilityCompared(state) {
    return state.teamBillabilityCompared
  },
  
  /** Statistics **/
  getBillabilityStatistics(state) {
    return state.billabilityStatistics
  },
  
  getBillabilityStatisticsSecondPeriod(state) {
    return state.billabilityStatisticsSecondPeriod
  }
}
