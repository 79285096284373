import apiHandler from '@/utils/handlers/ApiHandler';
import {SET_USERS, UPDATE_USER_ROLE } from "@/store/users/mutations";

export const GET_USERS = 'getUsers';
export const CREATE_USER = 'createUser';
export const CHANGE_USER_ROLE = 'changeUserRole';
export const DELETE_USER = 'deleteUser';

export const actions = {
  getUsers({commit}, queryParams) {
    return apiHandler.get(`users`, queryParams).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_USERS, response.data);
        return true;
      }
      return false;
    })
  },

  createUser({commit}, data) {
    return apiHandler.post('users', data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    })
  },

  changeUserRole({commit}, {role, usersIds}) {
    return apiHandler.patch(`users/role`, {role: role, 'usersIds[]': usersIds}).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(UPDATE_USER_ROLE, {role, usersIds});
        return true;
      }
      return false;
    })
  },

  deleteUser({commit}, usersIds) {
    return apiHandler.delete(`users`, {'usersIds[]': usersIds}).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    })
  }
}
