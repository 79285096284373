import AuthGuard from '@/router/guards/auth';

const ManagerLayout = () => import('../../views/manager/Layout');
const Teams = () => import('../../views/manager/teams/Teams');

export const ROUTE_NAMES_TEAMS = Object.freeze({
  TEAMS: 'TeamsOverview',
});

export const teams = {
  path: '/teams',
  meta: {
    guard: AuthGuard,
    roles: ['admin'],
  },
  component: ManagerLayout,
  children: [
    {
      path: '',
      name: ROUTE_NAMES_TEAMS.TEAMS,
      component: Teams
    },
  ]
}
