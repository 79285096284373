import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'

export const state = {
  /** All employees - overview **/
  billabilityEmployees: [],
  billabilityEmployeesSecondPeriod: [],
  
  /** Employee Billability details **/
  employeeBillability: {},

  /** Employee Corrected billability **/
  employeeCorrectedBillability: [],

  /** All discplines - overview **/
  billabilityDisciplines: [],
  comparedBillabilityDisciplines: [],

  /** Discipline Billability details **/
  disciplineBillability: {},

  /** Discipline Corrected billability **/
  disciplineCorrectedBillability: [],

  /** Discipline List */
  discipineList: [],
  
  /** All teams - overview **/
  billabilityTeams: [],

  /** Team Billability details **/
  teamBillability: {},
  teamBillabilityCompared: [],

  /** Team Corrected billability **/
  teamCorrectedBillability: [],

  /** Billability statistics **/
  billabilityStatistics: {},
  billabilityStatisticsSecondPeriod: {},
}

const namespaced = false

export const billability = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
