import PerformanceItem from "@/models/performance/PerformanceItem";

export default class PerformanceTeam extends PerformanceItem{
  constructor({expectedHours, id, loggedHours, loggedPercentage, name, proActiveLoggedHours, proActiveLoggedPercentage, remindedLoggedHours, remindedLoggedPercentage}) {
    super({expectedHours, id, loggedHours, loggedPercentage});
    this.name = name;
    this.proActiveLoggedHours = proActiveLoggedHours;
    this.proActiveLoggedPercentage = proActiveLoggedPercentage;
    this.remindedLoggedHours = remindedLoggedHours;
    this.remindedLoggedPercentage = remindedLoggedPercentage;
  }


  static create(data) {
    return new PerformanceTeam(data)
  }
}
