export default class Absence {
  constructor({absences, displayName, id, isPartOfCluster = false}) {
    this.absences = absences; // Array of objects: day, hours
    this.displayName = displayName;
    this.id = id;

    this.isPartOfCluster = isPartOfCluster;
  }

  get totalHours() {
    if (this.absences <= 1) {
      return this.absences[0].hours;
    }
    return this.absences.map(i => i.hours).reduce((partialSum, a) => partialSum + a, 0);
  }

  get dayPlaceholder() {
    const absenceCount = this.absences.length;
    if (absenceCount <= 1) {
      return this.absences[0].day;
    }
    return `${this.absences[0].day} - ${this.absences[absenceCount - 1].day}`;
  }

  static create(data) {
    return new Absence(data);
  }
}