import apiHandler from '@/utils/handlers/ApiHandler';
import {SET_TEAMS} from "@/store/team/mutations";

export const CREATE_TEAM = 'createTeam';
export const GET_TEAMS = 'getTeams';
export const DELETE_TEAM = 'deleteTeam';
export const EDIT_TEAM = 'editTeam';

export const actions = {
  createTeam({commit}, data) {
    return apiHandler.post('team/create', data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return response.data;
      }
      return false;
    })
  },
  getTeams({commit}, searchTerm = "") {
    return apiHandler.get(`team/list?search=${searchTerm}`).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_TEAMS, response.data);
        return true;
      }
      return false;
    })
  },
  deleteTeam({commit}, teamId) {
    return apiHandler.delete(`team/${teamId}`, null).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    })
  },
  editTeam({commit}, {name, slackId, teamId}) {
    const data = {
      name: name,
      slackId: slackId
    }
    return apiHandler.put(`team/${teamId}`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    })
  },
}
