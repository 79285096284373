import PerformanceItem from "@/models/performance/PerformanceItem";

export default class PerformanceEmployee extends PerformanceItem{
  constructor({disciplineId, disciplineName, displayName, expectedHours, id, jiraId, link, loggedHours, loggedPercentage, proActiveLoggedHours, proActiveLoggedPercentage, remindedLoggedHours,
    remindedLoggedPercentage, teamId, teamName, week, year}) {
    super({expectedHours, id, loggedHours, loggedPercentage});
    this.disciplineId = disciplineId;
    this.disciplineName = disciplineName;
    this.displayName = displayName;
    this.jiraId = jiraId;
    this.disciplineId = jiraId;
    this.link = link;
    this.proActiveLoggedHours = proActiveLoggedHours;
    this.proActiveLoggedPercentage = proActiveLoggedPercentage;
    this.remindedLoggedHours = remindedLoggedHours;
    this.remindedLoggedPercentage = remindedLoggedPercentage;
    this.teamId = teamId;
    this.teamName = teamName;
    this.week = week;
    this.year = year;
  }

  static create(data) {
    return new PerformanceEmployee(data)
  }
}
