import {createStore} from 'vuex';
import {mutations} from './mutations';
import {actions} from './actions';
import {getters} from './getters';

import {auth} from './auth';
import {team} from './team';
import {employees} from './employees';
import {absence} from './absence';
import {performance} from './performance';
import {billability} from './billability';
import {user} from './users';

export const state = {
  apiError: null,

  /** Modal **/
  isModalShown: false,
  modalContent: null,
  activeModal: null,

  /** Toast **/
  toast: null,

  /** Loading spinner **/
  isLoading: false,

  /** Sorting **/
  sortingOptions: null,

  breadcrumbName: ''
};

const store = {
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth,
    team,
    employees,
    absence,
    performance,
    billability,
    user
  }
}

// create the store
const vuexStore = createStore(store);

export default vuexStore;
