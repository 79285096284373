import {LOGIN_FAILURE, LOGIN_SUCCESS, SET_USER_PROFILE} from "@/store/auth/mutations";
import LocalDataHandler from '@/utils/handlers/LocalDataHandler';
import apiHandler from '@/utils/handlers/ApiHandler';

export const SEND_LOGIN = 'sendLogin';
export const GET_USER_PROFILE = 'getUserProfile';
export const SEND_RESET_PASSWORD_LINK = 'sendResetPasswordLink';
export const RESET_PASSWORD = 'resetPassword';

export const actions = {
  sendLogin({commit}, data) {
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('client_id', process.env.VUE_APP_AUTH_CLIENT_ID);
    formData.append('username', data.email);
    formData.append('password', data.password);
    formData.append('client_secret', '');

    return apiHandler.post('auth/login', formData).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(LOGIN_SUCCESS, response.data);
        apiHandler.setApiType(true);
        LocalDataHandler.setAuthenticated();
        return true;
      }
      return false;
    }).catch(error => {
      console.error(error);
      commit(LOGIN_FAILURE, error);
      return false;
    });
  },

  getUserProfile({commit}) {
    return apiHandler.get('/users/profile').then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_USER_PROFILE, response.data)
        return true;
      }
      return false;
    })
  },

  sendResetPasswordLink({commit}, data) {
    return apiHandler.post('/forgot-password', data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      console.error(error);
      return false;
    });
  },

  resetPassword({commit}, payload) {
    const data = {
      password: payload.password,
    };
    
    return apiHandler.post(`/reset-password/${payload.tokenId}/${payload.token}`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      console.error(error);
      return false;
    });
  },
}
