import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import apiHandler from "./utils/handlers/ApiHandler";
import '@/setup/font-awesome';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/** Set up the api interceptors **/
apiHandler.setInterceptors(store, router);

createApp(App).use(store).use(router).component("font-awesome-icon", FontAwesomeIcon).mount('#app')
