export default class Billability {
  constructor({id, name, correctedBillabilityRatio, generalBillabilityRatio, billableWorkingHours, correctedWorkingHours}) {
    this.id = id;
    this.name = name;
    this.correctedBillabilityRatio = correctedBillabilityRatio;
    this.generalBillabilityRatio = generalBillabilityRatio;
    this.billableWorkingHours = billableWorkingHours;
    this.correctedWorkingHours = correctedWorkingHours;
  }

  get correctedBillabilityText() {
    return this.correctedBillabilityRatio !== null ? `${this.correctedWorkingHours}h (${this.correctedBillabilityRatio}%)` : 'N/A' ;
  }

  get generalBillabilityText() {
    return this.generalBillabilityRatio !== null ? `${this.billableWorkingHours}h (${this.generalBillabilityRatio}%)` : 'N/A';
  }

  get hasCorrectedBillability() {
    return this.correctedBillabilityRatio !== null;
  }

  static create(data) {
    return new Billability(data);
  }
}
