import AuthGuard from '@/router/guards/auth';

const ManagerLayout = () => import('../../views/manager/Layout');
const Overview = () => import('../../views/manager/employees/Overview');

export const ROUTE_NAMES_EMPLOYEES = Object.freeze({
  OVERVIEW: 'EmployeesDashboard',
});

export const employees = {
  path: '/employees',
  meta: {
    guard: AuthGuard,
    roles: ['admin'],
  },
  component: ManagerLayout,
  children: [
    {
      path: '',
      name: ROUTE_NAMES_EMPLOYEES.OVERVIEW,
      component: Overview
    },
  ]
}
