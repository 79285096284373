export const KEYS_SORTING = {
  // Performance
  WEEK_RATING: 'loggedPercentage',
  PROACTIVE_LOGGING: 'proActivePercentage',
  REMINDED_LOGGING: 'remindedPercentage',
  REMINDED_LOGGED_PERCENTAGE: 'remindedLoggedPercentage',
  PROACTIVE_LOGGED_PERCENTAGE: 'proActiveLoggedPercentage',
  DISPLAY_NAME: 'displayName',
  NAME: 'name',

  // Absence
  DAY: 'day',

  // Billability
  GENERAL_BILLABILITY_RATIO: 'generalBillabilityRatio',
  CORRECTED_BILLABILITY_RATIO: 'correctedBillabilityRatio',

  // User
  ADDED: 'createdAt',
};

export const TYPES_SORTING = {
  ASCENDING: 0,
  DESCENDING: 1,

  // Performance
  ASC_WEEK_RATING: 1,
  DESC_WEEK_RATING: 2,
  ASC_PROACTIVE_LOGGING: 3,
  DESC_PROACTIVE_LOGGING: 4,
  ASC_REMINDED_LOGGING: 5,
  DESC_REMINDED_LOGGING: 6,

  // Absence
  ASC_START_DATE: 1,
  DESC_START_DATE: 2,
  ASC_END_DATE: 3,
  DESC_END_DATE: 4,

  //Billability
  ASC_GENERAL_RATIO: 1,
  DESC_GENERAL_RATIO: 2,
  ASC_CORRECTED_RATIO: 3,
  DESC_CORRECTED_RATIO: 4,
};

export class SortingOption {
  constructor(id, sortingKey, sortingType, sortingMethod = null) {
    this.id = id;
    this.sortingKey = sortingKey;
    this.sortingType = sortingType;
    this.sortingMethod = sortingMethod;
    this.performSorting = this.sortingMethod ? this.performLocalSorting : this.performRemoteSorting;
  }

  executeSorting(data) {
    return this.performSorting(data);
  }

  performRemoteSorting() {
    return {
      sortingKey: this.sortingKey,
      sortingType: this.sortingType
    };
  }

  performLocalSorting(data) {
    return this.sortingMethod(data, this.sortingKey);
  }
}

export const sortAsc = (data, varName) => {
  return data.sort(function (a, b) {
    if (a[varName] === b[varName]) {
      return 0
    } else if (!a[varName] && b[varName]) {
      return -1;
    } else if (a[varName] && !b[varName]) {
      return 1;
    } else {
      if (a[varName] < b[varName]) {
        return -1;
      }
      if (a[varName] > b[varName]) {
        return 1;
      }
      return 0
    }
  });
}

export const sortDesc = (data, varName) => {
  return data.sort(function (a, b) {
    if (a[varName] === b[varName]) {
      return 0
    } else if (!a[varName] && b[varName]) {
      return 1;
    } else if (a[varName] && !b[varName]) {
      return -1;
    } else {
      if (a[varName] > b[varName]) {
        return -1;
      }
      if (a[varName] < b[varName]) {
        return 1;
      }
      return 0
    }
  });
}

export const sortDateHighLow = (data, varName) => {
  return data.sort(function (a, b) {
    let aDate = a[varName]
    let bDate = b[varName]
    if (aDate === bDate) {
      return 0
    } else if (!aDate && bDate) {
      return 1;
    } else if (aDate && !bDate) {
      return -1;
    } else {
      aDate = new Date(`${a[varName]}Z`.replace(/\s+/g, 'T'))
      bDate = new Date(`${b[varName]}Z`.replace(/\s+/g, 'T'))
      if (aDate.getTime() > bDate.getTime()) {
        return -1;
      }
      if (aDate.getTime() < bDate.getTime()) {
        return 1;
      }
      return 0
    }
  });
}

export const sortDateLowHigh = (data, varName) => {
  return data.sort(function (a, b) {
    let aDate = a[varName]
    let bDate = b[varName]
    if (aDate === bDate) {
      return 0
    } else if (!aDate && bDate) {
      return -1;
    } else if (aDate && !bDate) {
      return 1;
    } else {
      aDate = new Date(`${a[varName]}Z`.replace(/\s+/g, 'T'))
      bDate = new Date(`${b[varName]}Z`.replace(/\s+/g, 'T'))
      if (aDate.getTime() < bDate.getTime()) {
        return -1;
      }
      if (aDate.getTime() > bDate.getTime()) {
        return 1;
      }
      return 0
    }
  })

}

export const sortTrueToFalse = (data, varName) => {
  return data.sort(function (a, b) {
    if (a[varName]) {
      return -1
    }
    if (!a[varName]) {
      return 1
    }
    return 0
  });
}

export const sortFalseToTrue = (data, varName) => {
  return data.sort(function (a, b) {
    if (b[varName]) {
      return -1
    }
    if (!b[varName]) {
      return 1
    }
    return 0
  });
}
