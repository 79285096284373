/** FontAwesome**/
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faArrowRight,
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faCaretRight,
  faChevronLeft,
  faChevronRight,
  faEdit,
  faSearch,
  faSignOutAlt,
  faSort,
  faSpinner,
  faTimes,
  faTrash
} from '@fortawesome/free-solid-svg-icons'

library.add(faSpinner, faSearch, faTimes, faArrowLeft, faArrowRight, faSignOutAlt, faCaretDown, faCaretLeft,
  faCaretRight, faChevronLeft, faChevronRight, faSort, faCaretUp, faTrash, faEdit);
