import AuthGuard from '@/router/guards/auth';

const ManagerLayout = () => import('../../views/manager/Layout');
const Overview = () => import('../../views/manager/absence/Overview');

export const ROUTE_NAMES_ABSENCE = Object.freeze({
  OVERVIEW: 'ABSENCE_OVERVIEW',
});

export const absence = {
  path: '/absence',
  meta: {
    guard: AuthGuard,
    roles: ['admin'],
  },
  component: ManagerLayout,
  children: [
    {
      path: '',
      name: ROUTE_NAMES_ABSENCE.OVERVIEW,
      component: Overview
    },
  ]
}
