export default class BillabilityCompared {
  constructor({
    id,
    name,
    correctedBillabilityRatio,
    generalBillabilityRatio,
    generalBillabilityRatioCompared,
    correctedBillabilityRatioCompared,
    generalBillabilityChange,
    correctedBillabilityChange,
    billableWorkingHours,
    billableWorkingHoursCompared,
    correctedWorkingHours,
    correctedWorkingHoursCompared
  }) {
    this.id = id;
    this.name = name;
    this.correctedBillabilityRatio = correctedBillabilityRatio;
    this.generalBillabilityRatio = generalBillabilityRatio;
    this.generalBillabilityRatioCompared = generalBillabilityRatioCompared;
    this.correctedBillabilityRatioCompared = correctedBillabilityRatioCompared;
    this.generalBillabilityChange = generalBillabilityChange;
    this.correctedBillabilityChange = correctedBillabilityChange;
    this.billableWorkingHours = billableWorkingHours;
    this.billableWorkingHoursCompared = billableWorkingHoursCompared;
    this.correctedWorkingHours = correctedWorkingHours;
    this.correctedWorkingHoursCompared = correctedWorkingHoursCompared;
  }

  get correctedBillabilityText() {
    return this.correctedBillabilityRatio !== null
      ? `${this.correctedBillabilityRatio}%`
      : "N/A";
  }

  get generalBillabilityText() {
    return this.generalBillabilityRatio !== null
      ? `${this.generalBillabilityRatio}%`
      : "N/A";
  }

  get hasCorrectedBillability() {
    return this.correctedBillabilityRatio !== null;
  }

  static create(data) {
    return new BillabilityCompared(data);
  }
}
