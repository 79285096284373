import AuthGuard from '@/router/guards/auth';

const ManagerLayout = () => import('../../views/manager/Layout');
const Overview = () => import('../../views/manager/billability/Overview');
const EmployeeBillabilityDetails = () => import('../../views/manager/billability/EmployeeBillabilityDetails');
const EmployeeCorrectedBillability = () => import ('../../views/manager/billability/EmployeeCorrectedBillability')
const EmployeeDetailsCorrectedBillability = () => import ('../../views/manager/billability/EmployeeDetailsCorrectedBillability')
const DisciplineBillabilityDetails = () => import('../../views/manager/billability/DisciplineBillabilityDetails');
const DisciplineCorrectedBillability = () => import('../../views/manager/billability/DisciplineCorrectedBillability');
const DisciplineDetailsCorrectedBillability = () => import('../../views/manager/billability/DisciplineDetailsCorrectedBillability');
const TeamBillabilityDetails = () => import('../../views/manager/billability/TeamBillabilityDetails');
const TeamCorrectedBillability = () => import('../../views/manager/billability/TeamCorrectedBillability');
const TeamDetailsCorrectedBillability = () => import('../../views/manager/billability/TeamDetailsCorrectedBillability');

export const ROUTE_NAMES_BILLABILITY = Object.freeze({
  OVERVIEW: 'BILLABILITY_OVERVIEW',

  /** Employees **/
  EMPLOYEE_DETAILS: 'BILLABILITY_EMPLOYEE_DETAILS',
  CORRECTED_BILLABILITY_FROM_OVERVIEW: 'CORRECTED_BILLABILITY_FROM_OVERVIEW',
  CORRECTED_BILLABILITY_FROM_EMPLOYEE_DETAILS: 'CORRECTED_BILLABILITY_FROM_EMPLOYEE_DETAILS',

  /** Disciplines **/
  DISCIPLINE_DETAILS: 'BILLABILITY_DISCIPLINE_DETAILS',
  CORRECTED_BILLABILITY_FROM_DISCIPLINE_OVERVIEW: 'CORRECTED_BILLABILITY_FROM_DISCIPLINE_OVERVIEW',
  CORRECTED_BILLABILITY_FROM_DISCIPLINE_DETAILS: 'CORRECTED_BILLABILITY_FROM_DISCIPLINE_DETAILS',

  /** Teams **/
  TEAM_DETAILS: 'BILLABILITY_TEAM_DETAILS',
  CORRECTED_BILLABILITY_FROM_TEAM_OVERVIEW: 'CORRECTED_BILLABILITY_FROM_TEAM_OVERVIEW',
  CORRECTED_BILLABILITY_FROM_TEAM_DETAILS: 'CORRECTED_BILLABILITY_FROM_TEAM_DETAILS',
});

export const billability = {
  path: '/billability',
  meta: {
    guard: AuthGuard,
    roles: ['admin', 'app'],
  },
  component: ManagerLayout,
  children: [
    {
      path: '',
      name: ROUTE_NAMES_BILLABILITY.OVERVIEW,
      component: Overview
    },
    {
      path: 'employee/details/:employeeId',
      name: ROUTE_NAMES_BILLABILITY.EMPLOYEE_DETAILS,
      component: EmployeeBillabilityDetails,
      meta: {
        // Used in the Breadcrumbs.vue
        backButtonRoute: ROUTE_NAMES_BILLABILITY.OVERVIEW,

        breadcrumb: [
          {
            name: ROUTE_NAMES_BILLABILITY.OVERVIEW,
            text: 'Billability'
          },
          {
            name: ROUTE_NAMES_BILLABILITY.EMPLOYEE_DETAILS,
            textWithName: (name = "") => `${name}`
          },
        ],
      }
    },
    {
      // dateType refers to the type of filter being used when accessing the route
      // Either Week (week-weekNumber), Month (monthName) or Date range (date-range)
      path: 'employee/:employeeId/corrected-billability-ratio/:dateType/:startDate/:endDate',
      // Corrected billability accessed from the billability Overview
      name: ROUTE_NAMES_BILLABILITY.CORRECTED_BILLABILITY_FROM_OVERVIEW, 
      component: EmployeeCorrectedBillability,
      meta: {
        // Used in the Breadcrumbs.vue
        backButtonRoute: ROUTE_NAMES_BILLABILITY.OVERVIEW,

        breadcrumb: [
          {
            name: ROUTE_NAMES_BILLABILITY.OVERVIEW,
            text: 'Billability'
          },
          {
            name: ROUTE_NAMES_BILLABILITY.CORRECTED_BILLABILITY_FROM_OVERVIEW,
            text: 'Corrected billability ratio'
          },
        ],
      }
    },
    {
      // weekOrMonth represents the value of the weekNumber or the month name obtained from the api.
      // dateType represents the type filter being either 'week' or 'month'
      path: 'employee/details/:employeeId/corrected-billability-ratio/:weekOrMonth/:dateType/:year',
      // This route is accessed from the Employee billability details page. It's more optimal to keep these routes separate.
      name: ROUTE_NAMES_BILLABILITY.CORRECTED_BILLABILITY_FROM_EMPLOYEE_DETAILS,
      component: EmployeeDetailsCorrectedBillability,
      meta: {
        // Used in the Breadcrumbs.vue
        backButtonRoute: ROUTE_NAMES_BILLABILITY.EMPLOYEE_DETAILS,
        disableBreadcrumbReset: true,
        breadcrumb: [
          {
            name: ROUTE_NAMES_BILLABILITY.OVERVIEW,
            text: 'Billability'
          },
          // TODO: ASK BACKEND TO GET NAME OF EMPLOYEE GET CORRECTED BILLABILITY
          {
            name: ROUTE_NAMES_BILLABILITY.EMPLOYEE_DETAILS,
            textWithName: (name = "") => `${name}`
          },
          {
            name: ROUTE_NAMES_BILLABILITY.CORRECTED_BILLABILITY_FROM_EMPLOYEE_DETAILS,
            text: 'Corrected billability ratio'
          },
        ],
      }
    },
    {
      path: 'discipline/details/:disciplineId',
      name: ROUTE_NAMES_BILLABILITY.DISCIPLINE_DETAILS,
      component: DisciplineBillabilityDetails,
      meta: {
        // Used in the Breadcrumbs.vue
        backButtonRoute: ROUTE_NAMES_BILLABILITY.OVERVIEW,

        breadcrumb: [
          {
            name: ROUTE_NAMES_BILLABILITY.OVERVIEW,
            text: 'Billability'
          },
          {
            name: ROUTE_NAMES_BILLABILITY.DISCIPLINE_DETAILS,
            textWithName: (name = "") => `${name}`
          },
        ],
      }
    },
    {
      // dateType refers to the type of filter being used when accessing the route
      // Either Week (week-weekNumber), Month (monthName) or Date range (date-range)
      path: 'discipline/:disciplineId/corrected-billability-ratio/:dateType/:startDate/:endDate',
      // Corrected billability accessed from the billability Overview
      name: ROUTE_NAMES_BILLABILITY.CORRECTED_BILLABILITY_FROM_DISCIPLINE_OVERVIEW, 
      component: DisciplineCorrectedBillability,
      meta: {
        // Used in the Breadcrumbs.vue
        backButtonRoute: ROUTE_NAMES_BILLABILITY.OVERVIEW,

        breadcrumb: [
          {
            name: ROUTE_NAMES_BILLABILITY.OVERVIEW,
            text: 'Billability'
          },
          {
            name: ROUTE_NAMES_BILLABILITY.CORRECTED_BILLABILITY_FROM_DISCIPLINE_OVERVIEW,
            text: 'Corrected billability ratio'
          },
        ],
      }
    },
    {
      // weekOrMonth represents the value of the weekNumber or the month name obtained from the api.
      // dateType represents the type filter being either 'week' or 'month'
      path: 'discipline/details/:disciplineId/corrected-billability-ratio/:weekOrMonth/:dateType/:year',
      // This route is accessed from the Employee billability details page. It's more optimal to keep these routes separate.
      name: ROUTE_NAMES_BILLABILITY.CORRECTED_BILLABILITY_FROM_DISCIPLINE_DETAILS,
      component: DisciplineDetailsCorrectedBillability,
      meta: {
        // Used in the Breadcrumbs.vue
        backButtonRoute: ROUTE_NAMES_BILLABILITY.DISCIPLINE_DETAILS,
        disableBreadcrumbReset: true,

        breadcrumb: [
          {
            name: ROUTE_NAMES_BILLABILITY.OVERVIEW,
            text: 'Billability'
          },
          // TODO: ASK BACKEND TO GET NAME OF EMPLOYEE GET CORRECTED BILLABILITY
          {
            name: ROUTE_NAMES_BILLABILITY.DISCIPLINE_DETAILS,
            textWithName: (name = "") => `${name}`
          },
          {
            name: ROUTE_NAMES_BILLABILITY.CORRECTED_BILLABILITY_FROM_DISCIPLINE_DETAILS,
            text: 'Corrected billability ratio'
          },
        ],
      }
    },
    {
      path: 'team/details/:teamId',
      name: ROUTE_NAMES_BILLABILITY.TEAM_DETAILS,
      component: TeamBillabilityDetails,
      meta: {
        // Used in the Breadcrumbs.vue
        backButtonRoute: ROUTE_NAMES_BILLABILITY.OVERVIEW,

        breadcrumb: [
          {
            name: ROUTE_NAMES_BILLABILITY.OVERVIEW,
            text: 'Billability'
          },
          {
            name: ROUTE_NAMES_BILLABILITY.TEAM_DETAILS,
            textWithName: (name = "") => `${name}`
          },
        ],
      }
    },
    {
      // dateType refers to the type of filter being used when accessing the route
      // Either Week (week-weekNumber), Month (monthName) or Date range (date-range)
      path: 'team/:teamId/corrected-billability-ratio/:dateType/:startDate/:endDate',
      // Corrected billability accessed from the billability Overview
      name: ROUTE_NAMES_BILLABILITY.CORRECTED_BILLABILITY_FROM_TEAM_OVERVIEW, 
      component: TeamCorrectedBillability,
      meta: {
        // Used in the Breadcrumbs.vue
        backButtonRoute: ROUTE_NAMES_BILLABILITY.OVERVIEW,

        breadcrumb: [
          {
            name: ROUTE_NAMES_BILLABILITY.OVERVIEW,
            text: 'Billability'
          },
          {
            name: ROUTE_NAMES_BILLABILITY.CORRECTED_BILLABILITY_FROM_TEAM_OVERVIEW,
            text: 'Corrected billability ratio'
          },
        ],
      }
    },
    {
      // weekOrMonth represents the value of the weekNumber or the month name obtained from the api.
      // dateType represents the type filter being either 'week' or 'month'
      path: 'team/details/:teamId/corrected-billability-ratio/:weekOrMonth/:dateType/:year',
      // This route is accessed from the Employee billability details page. It's more optimal to keep these routes separate.
      name: ROUTE_NAMES_BILLABILITY.CORRECTED_BILLABILITY_FROM_TEAM_DETAILS,
      component: TeamDetailsCorrectedBillability,
      meta: {
        // Used in the Breadcrumbs.vue
        backButtonRoute: ROUTE_NAMES_BILLABILITY.TEAM_DETAILS,
        disableBreadcrumbReset: true,

        breadcrumb: [
          {
            name: ROUTE_NAMES_BILLABILITY.OVERVIEW,
            text: 'Billability'
          },
          // TODO: ASK BACKEND TO GET NAME OF EMPLOYEE GET CORRECTED BILLABILITY
          {
            name: ROUTE_NAMES_BILLABILITY.TEAM_DETAILS,
            textWithName: (name = "") => `${name}`
          },
          {
            name: ROUTE_NAMES_BILLABILITY.CORRECTED_BILLABILITY_FROM_TEAM_DETAILS,
            text: 'Corrected billability ratio'
          },
        ],
      }
    },
  ]
}
