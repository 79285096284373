import Employee from "@/models/employees/Employee";

export const SET_EMPLOYEES = 'setEmployees';
export const RESET_EMPLOYEES = 'resetEmployees';

export const mutations = {
  setEmployees(state, data) {
    state.employees = data.map(item => Employee.create(item));
  },
  resetEmployees(state) {
    state.employees = [];
  }
}
