import apiHandler from '@/utils/handlers/ApiHandler';
import {SET_EMPLOYEES} from "@/store/employees/mutations";

export const GET_EMPLOYEES = 'getEmployees';
export const EDIT_EMPLOYEE = 'editEmployee';
export const SET_STATUS = 'setStatus';

export const actions = {
  getEmployees({commit}, {searchTerm = "", status}) {
    return apiHandler.get(`employees?search=${searchTerm}&status=${status}`).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_EMPLOYEES, response.data);
        return true;
      }
      return false;
    })
  },
  editEmployee({commit}, payload) {
    const data = {
      teamId: payload.teamId,
      slackId: payload.slackId,
      hours: payload.hours,
      acceptableWorkPercentage: payload.workPercentage
    }

    return apiHandler.put(`employees/${payload.id}`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    })
  },

  setStatus({commit}, {employeeId, status}) {
    const data = {status}
    return apiHandler.put(`employees/${employeeId}/status`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    })
  }
}
