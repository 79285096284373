const Layout = () => import('@/views/auth/Layout.vue');
const Login = () => import('@/views/auth/Login.vue');
const ForgotPassword = () => import('@/views/auth/ForgotPassword.vue');
const ResetPassword = () => import('@/views/auth/ResetPassword.vue');

export const ROUTE_NAMES_AUTH = Object.freeze({
  AUTH: 'Auth',
  LOGIN: 'AuthLogin',
  FORGOT_PASSWORD: 'ForgotPassword',
  RESET_PASSWORD: 'ResetPassword'
});

export const auth = {
  path: '/auth',
  name: ROUTE_NAMES_AUTH.AUTH,
  component: Layout,
  children: [
    {
      path: 'login',
      name: ROUTE_NAMES_AUTH.LOGIN,
      meta: {
        // todo: decide if you need auto login
        // guard: AutoLoginGuard
        hideLoadingSpinner: true
      },
      component: Login
    },
    {
      path: 'forgot-password',
      name: ROUTE_NAMES_AUTH.FORGOT_PASSWORD,
      meta: {
        hideLoadingSpinner: true
      },
      component: ForgotPassword
    },
    {
      path: 'reset-password/:tokenId/:token',
      name: ROUTE_NAMES_AUTH.RESET_PASSWORD,
      meta: {
        hideLoadingSpinner: true
      },
      component: ResetPassword
    }
  ]
};
