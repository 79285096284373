import apiHandler from '@/utils/handlers/ApiHandler';
import {
  SET_DISCIPLINE_PERFORMANCE, SET_EMPLOYEE_PERFORMANCE,
  SET_PERFORMANCE_EMPLOYEES,
  SET_TEAM_PERFORMANCE
} from "@/store/performance/mutations";


/** Employees **/
export const GET_PERFORMANCE_EMPLOYEES = 'getPerformanceEmployees';
export const GET_EMPLOYEE_PERFORMANCE = 'getEmployeePerformance';

/** Discipline **/
export const GET_DISCIPLINE_PERFORMANCE = 'getDisciplinePerformance';

/** Team **/
export const GET_TEAM_PERFORMANCE = 'getTeamPerformance';

export const actions = {
  /** Employees **/
  getPerformanceEmployees({commit}, payload) {
    const data = {
      week: payload.week,
      year: payload.year,
      search: payload.searchTerm,
    }
    return apiHandler.get('/performance/employees', data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_PERFORMANCE_EMPLOYEES, response.data);
        return true;
      }
      return false;
    })
  },
  getEmployeePerformance({commit}, {employeeId, year}) {
    const data = {
      year
    }
    return apiHandler.get(`/performance/employees/${employeeId}`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_EMPLOYEE_PERFORMANCE, response.data);
        return true;
      }
      return false;
    })
  },

  /** Discipline **/
  getDisciplinePerformance({commit}, payload) {
    const data = {
      week: payload.week,
      year: payload.year,
      search: payload.searchTerm,
    }
    return apiHandler.get('/performance/discipline', data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_DISCIPLINE_PERFORMANCE, response.data);
        return true;
      }
      return false;
    })
  },

  /** Team **/
  getTeamPerformance({commit}, payload) {
    const data = {
      week: payload.week,
      year: payload.year,
      search: payload.searchTerm,
    }
    return apiHandler.get('/performance/team', data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_TEAM_PERFORMANCE, response.data);
        return true;
      }
      return false;
    })
  },
}
