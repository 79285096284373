import Team from "@/models/teams/Team";

export const SET_TEAMS = 'setTeams';
export const SET_SELECTED_TEAM = 'setSelectedTeam';

export const mutations = {
  setTeams(state, data) {
    state.teams = data.map(item => Team.create(item));
  },
  setSelectedTeam(state, data) {
    state.selectedTeam = data;
  },
}
