import PerformanceEmployee from "@/models/performance/PerformanceEmployee";
import PerformanceDiscipline from "@/models/performance/PerformanceDiscipline";
import PerformanceStatistic from "@/models/performance/PerformanceStatistic";
import PerformanceTeam from "@/models/performance/PerformanceTeam";
import EmployeePerformanceDetails from "@/models/performance/EmployeePerformanceDetails";
import {TYPES_SORTING} from "@/models/SortingHandler";
import vuexStore from "@/store";

export const SET_PERFORMANCE_STATISTICS = 'setPerformanceStatistics';
export const SORT_PERFORMANCE_DATA = 'sortPerformanceData';

/** Employees **/
export const SET_PERFORMANCE_EMPLOYEES = 'setPerformanceEmployees';
export const CLEAR_PERFORMANCE_EMPLOYEES = 'clearPerformanceEmployees';
export const SORT_PERFORMANCE_EMPLOYEES = 'sortPerformanceEmployees';
export const SET_EMPLOYEE_PERFORMANCE = 'setEmployeePerformance';
export const SORT_EMPLOYEE_PERFORMANCE = 'sortEmployeePerformance';
export const SET_NAVIGATED_FROM = 'setNavigatedFrom'
export const CLEAR_NAVIGATED_FROM = 'clearNavigatedFrom'

/** Discipline **/
export const SET_DISCIPLINE_PERFORMANCE = 'setDisciplinePerformance';
export const CLEAR_DISCIPLINE_PERFORMANCE = 'clearDisciplinePerformance';
export const SORT_DISCIPLINE_PERFORMANCE  = 'sortDisciplinePerformance';

/** Team **/
export const SET_TEAM_PERFORMANCE = 'setTeamPerformance';
export const CLEAR_TEAM_PERFORMANCE = 'clearTeamPerformance';
export const SORT_TEAM_PERFORMANCE = 'sortTeamPerformance';

export const mutations = {
  setPerformanceStatistics(state, data) {
    state.performanceStatistics = PerformanceStatistic.create(data);
  },
  sortPerformanceData(state, property) {
    const {sortingType, sortingKey} = vuexStore.state.sortingOptions;
    state[property] = state[property].sort((item1, item2) => {
      if (sortingType === TYPES_SORTING.DESCENDING) {
        return item1[sortingKey] > item2[sortingKey] ? 1 : (item1[sortingKey] < item2[sortingKey] ? -1 : 0)
      } else {
        return item1[sortingKey] < item2[sortingKey] ? 1 : (item1[sortingKey] > item2[sortingKey] ? -1 : 0)
      }
    });
  },

  /** Employees **/
  setPerformanceEmployees(state, data) {
    // Set the statistics.
    this.commit(SET_PERFORMANCE_STATISTICS, data);

    // Set the employees.
    state.employees = data.employees.map(item => PerformanceEmployee.create(item));

    // Sort the data if the previous data was sorted.
    if (vuexStore.state.sortingOptions) {
      this.commit(SORT_PERFORMANCE_EMPLOYEES);
    }
  },
  sortPerformanceEmployees(state) {
    this.commit(SORT_PERFORMANCE_DATA, 'employees');
  },
  clearPerformanceEmployees(state) {
    state.employees = [];
  },
  setEmployeePerformance(state, data) {
    state.employeePerformance = {
      displayName: data.displayName,
      data: data ? data.data.map(item => EmployeePerformanceDetails.create(item)) : [],
    };

    // Sort the data if the previous data was sorted.
    if (vuexStore.state.sortingOptions) {
      this.commit(SORT_EMPLOYEE_PERFORMANCE);
    }
  },
  sortEmployeePerformance(state) {
    const {sortingType, sortingKey} = vuexStore.state.sortingOptions;
    state.employeePerformance.data = state.employeePerformance.data.sort((item1, item2) => {
      if (sortingType === TYPES_SORTING.DESCENDING) {
        return item1[sortingKey] > item2[sortingKey] ? 1 : (item1[sortingKey] < item2[sortingKey] ? -1 : 0)
      } else {
        return item1[sortingKey] < item2[sortingKey] ? 1 : (item1[sortingKey] > item2[sortingKey] ? -1 : 0)
      }
    });
  },
  setNavigatedFrom(state, weekAndYear) {
    state.navigatedFrom = weekAndYear;
  },
  clearNavigatedFrom(state) {
    state.navigatedFrom = null;
  },

  /** Discipline **/
  setDisciplinePerformance(state, data) {
    // Set the statistics.
    this.commit(SET_PERFORMANCE_STATISTICS, data);

    // Set the employees.
    state.disciplinePerformance = data.disciplines.map(item => PerformanceDiscipline.create(item));

    // Sort the data if the previous data was sorted.
    if (vuexStore.state.sortingOptions) {
      this.commit(SORT_DISCIPLINE_PERFORMANCE);
    }
  },
  clearDisciplinePerformance(state) {
    state.disciplinePerformance = [];
  },
  sortDisciplinePerformance(state) {
    this.commit(SORT_PERFORMANCE_DATA, 'disciplinePerformance');
  },

  /** Team **/
  setTeamPerformance(state, data) {
    // Set the statistics.
    this.commit(SET_PERFORMANCE_STATISTICS, data);

    // Set the employees.
    state.teamPerformance = data.teams.map(item => PerformanceTeam.create(item));

    // Sort the data if the previous data was sorted.
    if (vuexStore.state.sortingOptions) {
      this.commit(SORT_TEAM_PERFORMANCE);
    }
  },
  sortTeamPerformance(state) {
    this.commit(SORT_PERFORMANCE_DATA, 'teamPerformance');
  },
  clearTeamPerformance(state) {
    state.teamPerformance = [];
  },
}
