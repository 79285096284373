import LocalDataHandler from "@/utils/handlers/LocalDataHandler";
import apiHandler from '@/utils/handlers/ApiHandler'

export const LOGIN_SUCCESS = 'loginSuccess';
export const LOGIN_FAILURE = 'loginFailure';
export const SET_LOGOUT = 'setLogout';
export const SET_USER_PROFILE = 'setUserProfile'

export const mutations = {
  loginSuccess(state, payload) {
    LocalDataHandler.setKeepMeLoggedIn(payload);
    LocalDataHandler.setAccessToken(payload.access_token, payload.expires_in);
    LocalDataHandler.setRefreshToken(payload.refresh_token, LocalDataHandler.VALUES.REFRESH_TOKEN_EXPIRE_TIME);
    LocalDataHandler.setUserType(payload.scope);
    state.isAuthenticated = true;
    state.authError = '';
  },
  loginFailure(state, error) {
    state.isAuthenticated = false;
    state.authError = error.response.data;
  },
  setLogout(state) {
    LocalDataHandler.destroy();
    LocalDataHandler.clearLocalData();
    apiHandler.setApiType(false);
    state.isAuthenticated = false;
    state.authError = '';
  },

  setUserProfile(state, payload) {
    state.userProfile = payload
  }
}
