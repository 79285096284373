import { graphTexts } from "./BillabilityStatistic";

export default class BillabilityComparedStatistic {
  constructor({
    averageGeneralBillabilityRatio,
    averageCorrectedBillabilityRatio,
    averageGeneralBillabilityRatioCompared,
    averageCorrectedBillabilityRatioCompared,
    averageGeneralBillabilityRatioChange,
    averageCorrectedBillabilityRatioChange,
  }) {
    this.averageGeneralBillabilityRatio = averageGeneralBillabilityRatio;
    this.averageCorrectedBillabilityRatio = averageCorrectedBillabilityRatio;
    this.averageGeneralBillabilityRatioCompared = averageGeneralBillabilityRatioCompared;
    this.averageCorrectedBillabilityRatioCompared = averageCorrectedBillabilityRatioCompared;
    this.averageGeneralBillabilityRatioChange = averageGeneralBillabilityRatioChange;
    this.averageCorrectedBillabilityRatioChange = averageCorrectedBillabilityRatioChange;
  }

  graphText(property) {
    return graphTexts[property];
  }

  static create(data) {
    return new BillabilityComparedStatistic(data);
  }
}
