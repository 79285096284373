import Axios from 'axios'
import LocalDataHandler from './LocalDataHandler'
import ApiHeader from './ApiHeader'
import {SET_LOADING, SET_API_ERROR} from '@/store/mutations'
import {ROUTE_NAMES_ROOT} from "@/router/modules/";

const RESPONSE_TYPES = {
  STATUS_OK: 200,
  STATUS_CREATED: 201,
  STATUS_NO_CONTENT: 204,
  STATUS_UNAUTHORIZED: 401
}

class ApiHandler {
  constructor() {
    /** Create the api instance for auth APIs **/
    this.privateApi = Axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}/api`,
      // todo: update the properties depending on the back end setup
      'X-Csrf-Token': '1',
      // withCredentials: true,
      // headers: {
      //   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      // }
    })
    /** Create the api instance for public APIs **/
    // this.publicApi = Axios.create({
    //   baseURL: `${process.env.VUE_APP_API_URL}/api`,
    //   // todo: update the properties depending on the back end setup
    //   'X-Csrf-Token': '1'
    //   // headers: {
    //   //   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    //   // }
    // })
    this.controller = null;
    this.api = this.privateApi;
    this.store = null;
    this.requestCount = 0;
    this.router = null;
  }

  // Use this if you need both public & private API's.
  setApiType(isAuth) {
    if (!this.publicApi) return;
    this.api = isAuth ? this.privateApi : this.publicApi;
  }

  setInterceptors(store, router) {
    // TODO: USE PINIA AND REMOVE THE STORE PARAMETER
    this.store = store;
    this.router = router;
    this.controller = new AbortController();
    // Add a request interceptor
    this.api.interceptors.request.use(
      async(config) => {
        this.increaseRequestCount()
        /** here update the access token with refresh token with native fetch() **/
        if (LocalDataHandler.getAccessToken()) {
          // after new tokens retrieved, don't forget to set it in the headers for following API calls
          config.headers = ApiHeader.authHeader;
        }
        return config;
      },
      error => {
        this.decreaseRequestCount()
        // Do something with request error
        return Promise.reject(error)
      }
    );
    // Add a response interceptor
    this.api.interceptors.response.use(
      response => {
        this.decreaseRequestCount()
        // Do something with response data
        return response
      },
      error => {
        this.decreaseRequestCount()
        if (LocalDataHandler.getAccessToken()) {
          // We check if the user is logged in because the login failure error is parsed in its own mutation.
          // If the user is logged in, set the api error.
          this.store.commit(SET_API_ERROR, error);

          if (error.response.data.error == 'insufficient_scope') {
            router.push({name: ROUTE_NAMES_ROOT.LOGOUT})
          }
        }
        Promise.reject(error) // Shows the error in the console.
        return false
      }
    );
  }

  sendRequest(method, url, data = {}, params = {}, arrayBufferResponse = false) {
    return this.sendApi(this.api, method, url, data, params, arrayBufferResponse);
  }

  sendApi(instance, method, url, data = {}, params = {}, arrayBufferResponse = false) {
    return instance.request({
      method: method,
      url: url,
      data: data !== undefined ? data : {},
      params: params !== undefined ? params : {},
      responseType: arrayBufferResponse === true ? 'arraybuffer' : undefined,
      signal: this.controller.signal
    })
  }

  get(url, params = {}, responseType = false) {
    return this.sendRequest('GET', url, undefined, params, responseType)
  }

  post(url, data = {}, params = {}, fileResponse = {}) {
    return this.sendRequest('POST', url, data, params, fileResponse)
  }

  patch(url, data = {}, params = {}) {
    return this.sendRequest('PATCH', url, data, params)
  }

  put(url, data = {}, params = {}) {
    return this.sendRequest('PUT', url, data, params)
  }

  delete(url, data = {}) {
    return this.sendRequest('DELETE', url, data)
  }

  cancelRequest() {
    this.controller.abort();
  }

  increaseRequestCount() {
    this.requestCount++;
    if (this.requestCount > 0) {
      this.store.commit(SET_LOADING, true);
    }
  }

  decreaseRequestCount() {
    this.requestCount--;
    if (this.requestCount < 1) {
      this.store.commit(SET_LOADING, false);
    }
  }

  isSuccess(statusCode) {
    return (
      statusCode === RESPONSE_TYPES.STATUS_OK ||
      statusCode === RESPONSE_TYPES.STATUS_CREATED ||
      statusCode === RESPONSE_TYPES.STATUS_NO_CONTENT
    )
  }
}

export default new ApiHandler();
