import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'

export const state = {
  teams: [],
  selectedTeam: null,
}

const namespaced = false

export const team = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
