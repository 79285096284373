import LocalDataHandler from './LocalDataHandler'

class ApiHeader {
  constructor() {}

  get authHeader() {
    const accessToken = LocalDataHandler.getAccessToken();

    // Check if access token is present
    if (!accessToken) {
      return null
    } 
    
    return {
      Authorization: `Bearer ${LocalDataHandler.getAccessToken()}`,
      'accept-language': 'en'
    }
  }
}

export default new ApiHeader();