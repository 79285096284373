import apiHandler from '@/utils/handlers/ApiHandler';
import {SET_ABSENCES} from "@/store/absence/mutations";

export const GET_ABSENCES = 'getAbsences'
export const CREATE_ABSENCE = 'createAbsence';
export const EDIT_ABSENCE = 'editAbsence';
export const DELETE_ABSENCE = 'deleteAbsence';

export const actions = {
  getAbsences({commit}, {dateMin, dateMax, searchTerm}) {
    const params = `?${dateMin ? `dateMin=${dateMin}`: ''}${dateMax ? `&dateMax=${dateMax}`: ''}&search=${searchTerm}`;
    return apiHandler.get(`absence${params}`).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_ABSENCES, response.data);
        return true;
      }
      return false;
    })
  },

  createAbsence({commit}, data) {
    return apiHandler.post('absence', data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return response.data;
      }
      return false;
    })
  },
  editAbsence({commit}, data) {
    return apiHandler.put('absence', data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    })
  },

  deleteAbsence({commit}, data) {
    return apiHandler.post(`absence/delete`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    })
  }
}
